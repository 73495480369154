.resultCounter
{
    margin: 10px 0;
}
.cardWrapper
{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* width: auto; */
    margin :0 auto;
    

}
.cardBox
{
    margin: 10px 20px 10px 0;    
    width: 90%;
    
}

.journalSearchSection
{
    width: min(800px,90vw) ;
    height: 80px;
    padding:2% 2%;
    margin: 0 auto;
    background: var(--background-color);
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -30px;
    border-radius: 10px;
}
.journalSearchWrapperTop
{
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
}
.filterDiv
{
    display: flex;
    align-items: center;
}
.filterDiv>span
{
margin-top: 15px;
margin-right: 15px;
font-weight: 600;
}
.filterDiv label ,.filterDiv ul li
{
    font-family: 'Outfit',sans-serif !important;
}

.detailedRes
{
    font-size: 150%;
    font-weight: 700;
}
/* @media screen and (max-width:800px){
    .cardBox
    {
        width: 45%;
    }
    
} */
@media screen and (max-width:600px) {
    .cardBox
    {
    width: 95%;
    margin: 10px auto;
    }
    
}
.journalName
{
    text-transform: uppercase;
}
