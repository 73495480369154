:root {
  /* --primary-color:#0B5BA5; */
  --primary-color:#B99B6B;
  --background-color:#fff;
  --text:#151515;
  --box-shadow:rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

*
{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}
html
{
  scroll-behavior: smooth;
}
body
{
  font-family: 'Outfit', sans-serif;
  background: var(--background-color);
  color: var(--text);
  transition: 0.4s ease;
  /* width: 100vw;
  overflow-x: hidden; */
}
a,a:hover
{
  text-decoration: none;
}