@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
@import url('styles/accordion.css');
@import url('styles/cards.css');
@import url('styles/home.css');
@import url('styles/journal.css');
@import url('styles/nav.css');
@import url('styles/searchBox.css');

.marginSection {
    padding-top: 50px;
}

.paddingSection {
    padding: 0 6vw;
    width: 100%;
}

.supportedByWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.homeSupportedByWrapper {
    position: absolute !important;
    bottom: 2%;
}

.footerSection {
    background-color: #151515;
    counter-reset: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 30px;
}

.footerRow {
    width: 100%;
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

}

.footerRow>div {
    width: 40%;
    color: #737373;
    font-size: 80%;
}

.footerSearch {
    width: 40%;
}

.footersupportedByWrapper {
    align-items: flex-start;
}

.emptyDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
}

.emptyDiv img {
    width: 200px;

}

@media screen and (max-width:800px) {
    .footerRow {
        flex-direction: column;
        width: 100%;
    }

    .footerRow>div {
        width: 100%;
    }

    .footerSearch {
        margin-top: 20px;
    }

}

.individualJournalSection {
    margin: 5vh auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 70vh;
}

.indexingWrapper {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #A4A4A4;
    /* min-height: 250px; */
    border-radius: 10px;
    margin-top: 20px;
    position: relative;
}

.indexingHeading {
    width: 100%;
    background: rgb(221, 221, 221);
    
    font-size: 15px;
    padding: 12px 20px;
    border: 1px solid #A4A4A4;
    border-radius: 10px 10px 0 0;
}

.indexingDetails {
    width: 100%;
    padding: 12px 20px;
}

.indexingRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 1.1em;
    margin: 2px 0;
}

.indexQuery {
    color: #6C6C6C;
}

.indexIcon {
    font-size: 1.3em;
}

.journalDetailsWrapper {
    width: 60%;
}

@media screen and (max-width:800px) {
    .indexingWrapper {
        width: 95%;
    }

    .journalDetailsWrapper {
        width: 95%;
    }
    .accordionQuery
    {
        width: 60%;
    }
    .accordionRes
    {
        text-align: right;
    }

}