.card
{
    background: var(--background-color);
    color: var(--text);
    width: 100%;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    min-height: 80px;
    height: auto;
    padding-top: 15px;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    transition: 0.1s ease-in-out;
}
.card:hover
{
    background: #e6e6e6;
}
.cardTop
{
    display: flex;
    flex-direction: column;
}
.cardTop a
{
    color: var(--text);
}
.issn
{
    font-size: 13px;
    text-transform: uppercase;
    color: #767676;
    padding:0 15px ;
}
.cardTitle
{
    font-size: 1.3em;
    font-weight: 600;
    margin-top: 10px;
    text-transform: capitalize;
    padding: 0 15px;
}
.cardDates
{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    border: 1px solid #c3c3c3;
    border-radius: 25px;
    padding: 5px 12px;
    font-size: 80%;
    color:#767676 ;
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 20px;
    
    display: none;
}
.cardDates>span
{
    margin: 0 5px;
}
.cardDates>span:first-of-type
{
    font-size: 100%;
    color: #20C959;
}
.cardBottom
{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding:12px 20px;
    background: #D9D9D9;
    color: #111;
    margin:  20px;
    /* width: 100%;
    background: #D9D9D9;
    border: 1px solid #c3c3c3;
    min-height: 30px;
    margin-top: 20px;
    padding-right: 20px;*/
    border-radius: 5px ; 
 

}
.cardBottom a
{
    
    font-size: 15px;
    color: #111;
    text-decoration: none;
}

@media screen and (max-width:600px) {
    .card
    {
        flex-direction: column;
    }
    
}