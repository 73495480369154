.homeSection
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    height: 100svh;
    overflow: hidden;
    position: relative;
    /* background: url('../assets/bg.png'); */
    background-size: cover;
}
.homeLogo
{
    width: min(70vw,500px);
    height: auto;
}

.disclaimerSection
{
    position: absolute;
    bottom: -100%;
    padding: 25px;
    transition: 0.4s ease;
    background: var(--background-color);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.disclaimerSection>div>span,.disclaimerSection>div>p
{
    color: var(--text);
}
.disclaimerSection>div>p
{
    font-size: 90%;
}
.open
{
    bottom: 0;
}

.disclaimerCloseBtn
{
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--text);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--background-color);
    font-size: 130%;
    cursor: pointer;
    border-radius: 5px;
    
}
.searchBoxMessage
{
    width: 80%;
    margin: 10px auto;
    text-align: center;
}
.homeSection .searchBoxWrapper
{
    width: min(800px,90vw);
    margin: 40px auto;
}

@media screen and (max-width:800px) {
    .homeSection
    {
        /* background: url('../assets/mobilebg.png'); */
        background-size: cover;
    }
    
}