.headerSection
{
    background: var(--primary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 20px;
    min-height: 120px;
    color: #202020;
}
.headerTitle
{
    font-weight: 700;
    font-size: 1.5em;    
    text-align: center;
    text-transform: uppercase;
}
.breadCrumbs
{
    margin: 4px auto;
}
.breadCrumbs a
{
    margin: 0 4px;
    color: #202020;
    font-weight: 400;
    text-decoration: none;
    text-transform: capitalize;
}
.breadCrumbs a:last-of-type
{
    font-weight: 700;
}