.searchBoxDiv
{
    --border-color:#B5B5B5;
    --text-color:#a1a1a1;

    width: 100%;
    height: 50px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    border: 2px solid var(--border-color);
    border-radius: 12px;

}

.searchBoxDiv>span
{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 150%;
    font-weight: 800;
    color: var(--text-color);
    background: var(--background-color);
    border-radius: 10px  0 0 10px;
}
.searchBoxDiv>input,.searchBoxDiv>.searchBtn
{
    background: transparent;
    border: none;
}
.searchBoxDiv>input
{
    width: 70%;
    background: var(--background-color);
    color: var(--text);
    font-weight: 600;
    border: none;
    outline: none;
}
.searchBoxDiv>input:focus
{
    border: none;
    outline: none;
}
.searchBoxDiv>input::placeholder
{
    color: #999999;
    font-weight: 600;
}
.searchBoxDiv>.searchBtn
{
    width: 20%;
    background: var(--primary-color);
    font-size:1.2em ;
    font-weight: 600;
    border: 2px solid var(--primary-color);
    border-radius: 0 10px 10px 0;
    cursor: pointer;
    
}
.lightSearchBox {
    --border-color: #737373;
    --text-color: #737373;
}

.lightSearchBox>span {
    
    color: var(--text-color);
    background: transparent;
    border-radius: 10px 0 0 10px;
}

.lightSearchBox>input,
.lightSearchBox>.searchBtn {
    background: transparent;
    border: none;
}

.lightSearchBox>input {
    width: 70%;
    background: transparent;
    color: #F5F0E6;
    font-weight: 600;
    border: none;
    outline: none;
}

.lightSearchBox>input:focus {
    border: none;
    outline: none;
}

.lightSearchBox>input::placeholder {
    color: #999999;
    font-weight: 600;
}

.lightSearchBox>.searchBtn {
    width: 20%;
    background: #F5F0E6;
    font-size: 1.2em;
    font-weight: 600;
    border: 2px solid #F5F0E6;
    border-radius: 0 10px 10px 0;
    cursor: pointer;

}
@media screen and (max-width:800px) {
    .searchBoxDiv
    {
        height: 50px;
    }
    .searchBoxDiv>.searchBtn
    {
        font-size: 15px;
    }
    .searchBoxDiv>input
    {
        width: 60%;
    }
.searchBoxDiv>.searchBtn
    {
        width: 30%;
    }
}