.navBar
{
display: flex;
align-items: center;
justify-content: space-between;
padding: 2vh 6vw;
position: absolute;
width: 99%;
height: 50px;
z-index: 999;
}
.navLogo
{
    width: min(15vw,100px);
    height: auto;
}
.togglerButton 
{
    --size:40px;
    width: var(--size);
    height: var(--size);
    color: #151515;
    background: var(--primary-color);
    padding:10px;
    border-radius: 10px;
    border: 2px solid var(--primary-color);
    font-size: 140%;
    display: flex;
    align-items: center;
    justify-content: center;    
    cursor: pointer;
    transition: 0.4s ease;
}
.togglerButton:hover
{
    background: transparent;
    color: var(--primary-color);
}


@media screen and (max-width:800px) {
    .navLogo
    {
        width: 150px;
    }
        .togglerButton
        {
            --size:40px;
        }
}