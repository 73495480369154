.accordionWrapper
{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.accordion
{
    border-radius: 10px 10px 0 0 !important;
    box-shadow: none !important;
    border: 1px solid #c9c9c9 !important;

}
.journalName
{
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 20px;
}
.journalIssn
{
    font-weight: 400;
    margin: 6px 0;
    color: #767676;
}

.accordionSummary
{
    background: #EDEDED !important;
    border-radius: 10px 10px 0 0 !important;
}
.accordionRow
{
    display: flex;
    width: 95%;
    margin: 10px auto;
    padding: 10px 0;
    border-bottom: 1px solid #c9c9c9;
}
.accordionQuery
{
    width: 40%;
    font-weight: 600;
}
.accordionRes
{
    flex: 1;
}
.accordion .accordionRow:last-of-type
{
    border-bottom: none;
    padding-bottom: 0;
}